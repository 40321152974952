<template>
  <div class="readme-article">
    <h1 id="缴费申请">
      三、缴费申请
    </h1>
    <h2 id="转正">1.转正</h2>
    <p>查看可转正的线索记录，员工可将确报线索提交转正申请，必须选择班级、班主任及付款方式。</p>
    <p>
      <img src="@/assets/img/student/3-1.png">
    </p>
    <p>
      <img src="@/assets/img/student/3-2.png">
    </p>
    <h2 id="查看转正申请">1.查看转正申请</h2>
    <p>查看转正申请记录，展示转正申请缴费状态，可对未缴清申请进行缴费，创建合同等操作。</p>
    <p>
      <img src="@/assets/img/student/3-3.png">
    </p>
    <h2 id="续报">1.续报</h2>
    <p>查看已报名的正式学员列表，点击续报按钮可续报不同的专业。</p>
    <p>
      <img src="@/assets/img/student/3-4.png">
    </p>
    <p>
      <img src="@/assets/img/student/3-6.png">
    </p>
    <h2 id="查看续报申请">1.查看续报申请</h2>
    <p>查看续报申请记录，展示续报申请缴费状态，可对未缴费申请进行缴费，废弃等操作。</p>
    <p>
      <img src="@/assets/img/student/3-8.png">
    </p>
    <h2 id="定金">1.定金</h2>
    <p>查看可付定金的线索记录，员工可提交定金申请，必须选择或填写定金金额。</p>
    <p>
      <img src="@/assets/img/student/3-1.png">
    </p>
    <p>
      <img src="@/assets/img/student/3-5.png">
    </p>
    <h2 id="查看定金申请">1.查看定金申请</h2>
    <p>查看定金申请记录，展示定金申请支付状态，可对未支付申请进行缴费、废弃等操作。</p>
    <p>
      <img src="@/assets/img/student/3-7.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student3-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>